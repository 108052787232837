import React, { useState } from "react";
import ProjectGallery from "../projects/ProjectGallery";

const Galleries = ({ data = [] }) => {
  if (data.length > 0) {
    return <GalleryTabs data={data} />;
  }
  return null;
};

const GalleryTabs = ({ data }) => {
  const [onTab, setOnTab] = useState(0);

  return (
    <>
      <div className="gallery-tabs project-tabs">
        {data.map((x, idx) => (
          <button
            key={idx}
            onClick={() => setOnTab(idx)}
            className={`btn${onTab === idx ? ` active` : ""}`}
          >
            {x.title}
          </button>
        ))}
      </div>
      {data[onTab] && data[onTab].gallery && (
        <ProjectGallery gallery={data[onTab].gallery} />
      )}
    </>
  );
};

export default Galleries;
