import React from "react";
import CompletedGallery from "./CompletedGallery";
import OngoingGallery from "./OngoingGallery";

function GalleryContent() {
  return (
    <section className="residence-section">
      <div className="container">
        <div className="pb-4" id="synopsis">
          <h5 className="residence-section-title" id="synopsis">
            Residential Ongoing Projects
          </h5>
          <OngoingGallery />
        </div>
        <div className="pb-4" id="synopsis">
          <h5 className="residence-section-title" id="synopsis">
            Residential Completed Projects
          </h5>
          <CompletedGallery />
        </div>
      </div>
    </section>
  );
}

export default GalleryContent;
