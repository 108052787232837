import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";

function CompletedGallery() {
  const data = useStaticQuery(graphql`
    {
      allCompletedJson(sort: { fields: order, order: ASC }) {
        edges {
          node {
            title
            gallery {
              title
              images {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = data.allCompletedJson.edges.map(({ node }) => ({
    title: node.title,
    gallery: node.gallery
  }));

  return <Galleries data={images} />;
}

export default CompletedGallery;
