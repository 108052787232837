import React from "react";
import Layout from "../common/Layout";
import HeroSection from "../common/HeroSection";
import GalleryContent from "./GalleryContent";

function GalleryTemplate() {
  return (
    <Layout>
      <HeroSection title="Gallery" />
      <GalleryContent />
    </Layout>
  );
}

export default GalleryTemplate;
